a {
  text-decoration: none !important;
}

.tot {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #303030;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(10px);
}

.bg {
  min-height: 100vh; 
  background-color: #303030;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: -1;
}

.projectsBg:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -10;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.solidBg {
  min-height: 100vh; 
  background-color: #161b3a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 10vh;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  backdrop-filter: blur(10px);
  white-space: nowrap;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.rowProj {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  justify-content: space-around;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}

.animTitle::before {
  position: absolute;
  float: both;
  mix-blend-mode: difference;
  font-family: 'Baumans';
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  text-shadow: 0px 0px 25px #1f1f1f;
  content:"I'm Bogdan Dumitrescu";
}

.animTitle {
  font-family: 'Baumans';
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  padding: 10px;
  text-align: center;
  background: linear-gradient(130deg, rgba(252,177,255,1) 0%, rgba(175,175,255,1) 27%, rgba(145,255,230,1) 52%, rgba(255,163,200,1) 79%, rgba(138,202,255,1) 100%); 
  background-size: 400%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: Gradient 7s ease infinite;
  -webkit-animation: Gradient 7s ease infinite;
  -moz-animation: Gradient 7s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 90% 50%;
  }
  50% {
    background-position: 10% 50%;
  }
  100% {
    background-position: 90% 50%;
  }
}

.title {
  font-family: 'Baumans';
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
  padding: 10px;
}

.subtitle {
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
}

.subsubtitle {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  line-height: 1.15;
  font-size: 2rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
}
.subsubsubtitle {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  line-height: 1.15;
  font-size: 1.5rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
}

.description {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  margin: 1em 15vw 1em 15vw;
  color: #eaeaea;
  text-shadow: 3px 3px 4px #1f1f1f;
}

.title,
.subtitle,
.subsubtitle,
.subsubsubtitle,
.description {
  text-align: center;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.borderFade {
  padding: 10px 0px;
  padding-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
  margin: 10px;
  line-height: 1.15;
  font-size: 2rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
  position: relative;
  text-align: center;
  border-bottom: 2px solid hsla(243, 80%, 62%, 0);
  transform: scale(0.985);
  opacity: 0.8;
  transition: transform 0.5s ease, border-bottom 0.5s ease, opacity 0.5s ease;
}

.borderFade:hover {
  transform: scale(1);
  border-bottom: 2px solid hsla(243, 80%, 62%, 1);
  opacity: 1;
}

.borderFadeSmall {
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  margin: 20px;
  line-height: 1.15;
  font-size: 2rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
  position: relative;
  text-align: center;
  border-bottom: 2px solid hsla(243, 80%, 62%, 0);
  transform: scale(0.985);
  opacity: 0.9;
  transition: transform 0.5s ease, border-bottom 0.5s ease, opacity 0.5s ease;
}

.borderFadeSmall:hover {
  transform: scale(1);
  border-bottom: 2px solid hsla(243, 80%, 62%, 1);
  opacity: 1;
}

.borderFadeTiny {
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  margin: 0.5vh;
  line-height: 1.15;
  font-size: 1.5rem;
  color: #eaeaea;
  text-shadow: 2px 2px 5px #1f1f1f;
  position: relative;
  text-align: center;
  border-bottom: 2px solid hsla(243, 80%, 62%, 0);
  transform: scale(0.985);
  opacity: 0.9;
  transition: transform 0.5s ease, border-bottom 0.5s ease, opacity 0.5s ease;
}

.borderFadeTiny:hover {
  transform: scale(1);
  border-bottom: 2px solid hsla(243, 80%, 62%, 1);
  opacity: 1;
}

.tagsBig {
  display: flex;
  flex-direction: row;
  /* gap: 0.5em; */
  justify-content: center;
  white-space: nowrap;
  flex-wrap: wrap;
}

.tagBig {
  font-size: 1rem;
  margin: 0.2em;
  border: 1px solid #a19cff;
  padding: 0.25em 0.5em;
  border-radius: 1em;
  color: #bfbafd;
  transition: color 0.5s ease, border 0.5s ease;
}

.tagBig:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.tags {
  display: flex;
  flex-direction: row;
  /* gap: 0.5em; */
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
}

.tag {
  font-size: 0.8em;
  margin: 0.2em;
  border: 1px solid #888;
  padding: 0.25em 0.5em;
  border-radius: 1em;
  color: #ccc;
}

.descTag {
  font-size: 0.8em;
  margin: 0.2em;
  border: 1px solid #a19cff;
  padding: 0.25em 0.5em;
  border-radius: 1em;
  color: #bfbafd;
  transition: color 0.5s ease, border 0.5s ease;
}

.descTag:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.anchorPad {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardArea {
  height: 140px;
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.cardArea:hover {
  opacity: 1;
}

.cardAreaMobile {
  height: 85px;
  opacity: 1;
}

.but {
  user-select: none; 
}

.but:hover {
  cursor: pointer;
}

@media (max-width: 1023px) {
  .animTitle::before{
    font-family: 'Baumans';
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
    text-shadow: 2px 2px 25px #1f1f1f;
    content:"";
  }

  .animTitle {
    font-family: 'Baumans';
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
    padding: 10px;
    text-align: center;
    color: #eaeaea;
    background: #eaeaea;
    background-size: 400%;
    background-clip: text;
    -webkit-text-fill-color: #eaeaea;
    -webkit-background-clip: text;
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;
    text-shadow: 2px 2px 5px #1f1f1f;
  }

  .description {
    margin: 0.5vh 2vw 0 2vw;
    font-size: 1.2rem;
  }

  .footer {
    height: calc(60px + 9*(18px + 2rem));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    overflow-y: hidden;
    bottom: -430px;
  }

  .cardArea {
    opacity: 1;
  }

  .borderFade {
    opacity: 1;
  }
  
  .borderFadeSmall {
    opacity: 1;
  }
  
  .borderFadeTiny {
    opacity: 1;
  }
}